import React from 'react';
import Navbar from '../../layout/Navbar';
import Footer from '../../layout/Footer';

const RefundAndCancellation = () => {
  return (
    <div>
      <Navbar></Navbar>
      <div className="refund-cancellation">
        <h1>PULPIT’S CANCELLATION AND REFUND POLICY</h1>

        <section>
          <p>PULPIT Cab PVT. LTD. manages and offers these websites, applications, and services.</p>
          <p>
            These terms and conditions ("User Terms") govern all visits to and uses of the Site, the Service, and the Application by You, including any information, suggestions, and/or services made available to You on and via the Site or the Application.
            According to the Information Technology Act of 2000, its implementing rules, and the provisions of different legislation relating to electronic records as revised by it, this document is an electronic record. No digital or physical signatures are necessary for this electronic record since it is generated by a computer system.
          </p>
          <p>
            You acknowledge and agree to be bound by these User Terms by clicking the "I ACCEPT" button or by using PULPIT Cab's services. BE SURE TO READ AND UNDERSTAND ALL OF THESE USER TERMS PRIOR TO USING THE SITE. Please refrain from using the Site or using any of the services offered, if You do not desire to accept any of the User Terms.
          </p>
          <p>
            In relation to the use and services of the site, YOUR AGREEMENT TO THESE USER TERMS SHALL OPERATE AS A BINDING AGREEMENT BETWEEN YOU AND PULPIT Cab. You agree to be bound by these Terms by accessing and using the Services, and this creates a binding contract between you and PULPIT Cab. Previous agreements or arrangements with you are explicitly replaced by these Terms. In addition, PULPIT Cab may at any time stop providing the Services or any portion of them, as well as promptly terminate these Terms and any Services provided to you.
          </p>
          <p>
            Your acceptance of the User Terms shall be deemed to include your acceptance of the privacy policy available at (enter the hyperlink to pulpit’s privacy policy here). By accepting these User Terms, You also allow PULPIT Cab to send you promotional emails and SMS alerts from time to time.
          </p>
          <p>
            IT IS HEREBY EXPRESSLY CLAIMED THAT CORPORATE ENTITIES WHO ARE GOVERNED BY THE TERMS OF USE OF THE PULPIT Cab WILL NOT BE COVERED BY THESE USER TERMS. However, these user terms also apply to all authorized users taking "PULPIT Cab PVT. LTD.," that is, for customers who are booking rides through direct agreements between corporate entities and Pulpit Cab.
          </p>
        </section>

        <section>
          <h2>PAYMENT, REFUND AND OTHER RELATED USER TERMS AND CONDITIONS</h2>
          <p>
            At the start of each Ride, PULPIT Cab will provide You an estimate of the Total Ride Fee. This estimate from PULPIT Cab is liable to fluctuate due to a number of variables and is different from the actual Total Trip Fee assessed at the conclusion of the ride. According to the rules outlined in this document, the customer must pay the exact Total Journey price that is displayed at the completion of the ride.
          </p>
          <p>
            You acknowledge and agree that upon conclusion of the ride, you must pay PULPIT Cab the whole ride fee, including any applicable taxes. Any variation between the actual cost and the estimate at the conclusion of the journey must be paid by You to PULPIT Cab immediately. Convenience Fee/Access Fee imposed by Pulpit Cab toward the value-added services delivered by Pulpit Cab, as part of the Ride or otherwise, shall be decided and updated in Pulpit Cab's sole and absolute discretion. You acknowledge that this Additional Fee will be included in the receipt for the Total Ride Fee and agree to pay it.
          </p>
          <p>
            According to OUR CANCELLATION POLICY, you will be required to pay the applicable cancellation fee, which will be included in the receipt for the total ride fee.
          </p>
          <p>
            At the conclusion of the ride, PULPIT Cab shall issue a receipt for the Total Ride Fee payable by You; however, upon request, You shall get invoices from PULPIT Cab, the Driver, or the TPSP. For a copy of the invoices from our site, you may submit a request.
          </p>
          <p>
            You recognise that any relevant taxes are a part of the total ride fee and agree to pay them.
          </p>
          <p>
            After the ride is over, you can opt to pay the driver the whole ride fee using one of the payment methods offered to you by PULPIT Cab on the site.
          </p>
          <p>
            You may choose to pay the Total Ride Fee due by You in cash directly to the Driver upon completion of the Ride only if the chosen form of payment is Cash or if the Application allows you to pay Cash in such other cases.
          </p>
          <p>
            To process payment of the total ride fee, you will be required to provide necessary payment information, such as credit card, debit card, or net banking information (collectively, "Card Details"). You hereby authorize PULPIT Cab and a third party authorized by PULPIT Cab to provide payment gateway/processor services (the "Payment Processor") to access the Card Details for the purpose of processing the Total Ride Fee. In this regard, it is made clear that the Payment Processor, whose services are used for the Site, Application, and/or Services, must comply with PCI-DSS (Payment Card Industry - Data Security Standard) or any other standard that may be updated from time to time by the relevant agencies. By utilizing the Payment Processor's services, you acknowledge that you have read, understood, and agree to be bound by its Terms and Conditions, as may be updated from time to time.
          </p>
          <p>
            Any payment-related dispute must be settled between You and the Payment Processor, unless it stems from a bug or flaw in the Site.
          </p>
          <p>
            Except as otherwise determined by PULPIT Cab, the Total Ride Fee paid by You is final and non-refundable subject to these Customer Terms. PULPIT Cab will return any sums paid by You that are totally or partly refundable for whatever reason to the same account from which the payment was made, or as vouchers to be used in the Application if the payment could not be processed properly. You can get in touch with PULPIT Cab and/or its Associates if there are any problems as a matter.
          </p>
          <p>
            You acknowledge that using some promo codes or vouchers can result in different prices being charged for the same services, and that their usage won't affect your charges unless such promo codes have been made particularly available to you.
          </p>
          <p>
            In the event of a default or failure by You to pay the Total Ride Fee for any reason:
            <ul>
              <li>PULPIT Cab may prevent You from booking a new Ride using the Application until the outstanding Total Ride Fee of the prior Ride(s) has been paid by You;</li>
              <li>You agree that PULPIT Cab shall reclaim any outstanding funds payable by You for Ride(s) in respect of which Total Ride Fee has not been paid;</li>
              <li>You agree to pay any fees incurred by Pulpit Cab in an effort to recover any amounts owing by You to Pulpit Cab's Drivers under these Customer Terms, including debt collection agent fees, repossession fees, location search fees, process server fees, and solicitor fees charged on a client basis.</li>
            </ul>
          </p>
        </section>
        <section>
          <h2>CANCELLATION POLICY</h2>
          <p>
            A cancellation charge will be disclosed by PULPIT Cab on the application in the case that a ride is canceled. You accept and agree that you have the right to terminate your request for services at any time, subject to the cancellation fee described below. If you make an effort to cancel a reservation or service request, you will be informed in advance of the cancellation fee that will apply. The notification must appear on the site and/or the application. You understand and acknowledge that you must pay the cancellation fee in accordance with the payment conditions as part of the total ride fee for the following ride, or you must pay the outstanding cancellation fee before using the next available ride.
          </p>
          <p>
            PULPIT Cab shall issue a receipt for the Cancellation Fee, if any, payable by You for each cancellation; however, upon request, separate invoices for the Cancellation Fee and the Convenience Fee collected by the TPSPs shall be supplied to You. From the Support page, you may submit a request for a copy of the bills.
          </p>
          <p>
            You must pay the cancellation fee as soon as it is levied. PULPIT Cab may, in its sole discretion, add the Cancellation Fee that You Must Pay as a Component of the Total Ride Fee Due From Your Next Ride.
          </p>
          <p>
            If any of the following occurs, you can be assessed a cancellation fee:
            <ul>
              <li>If the driver's estimated arrival time is less than or equal to 10 minutes and you cancel the trip 3 minutes or later after the driver accepts your request,</li>
              <li>If the driver's estimated arrival time is 10 minutes or more and you cancel the reservation 5 minutes or later after the driver accepts your request,</li>
              <li>If the driver has already arrived at your pickup location, or if the driver cancels the reservation after waiting at your pickup location for more than 5 minutes.</li>
            </ul>
          </p>
          <p>
            The cancellation fee won't be applied, nevertheless, if the driver arrives at the pickup location more than 10 minutes after the indicated projected arrival time. Depending on your city and the chosen car category, there can be a difference in the cancellation fee amount. If there is a cancellation fee, it will be applied to the final fare for your future trip with PULPIT.
          </p>
          </section>
          <section>
          <h2>MANAGEMENT OF CUSTOMER ENGAGEMENT AND INDEMNIFICATION</h2>
          <p>
            When using our services, you can contact us in a number of ways, including by email, self-service app, website, or any other means you choose. You have the right to offer a fair rating for the service and ride once the ride is over. You acknowledge that while providing comments, feedback, testimonials, or reviews on or regarding the Rides or Services, you will do it in a fair, accurate, and unbiased manner.
          </p>
          <p>
            Any concern must be reported within 7 (seven) days of its occurrence; else, it won't be addressed effectively.
          </p>
          <p>
            PULPIT Cab will only do its best effort to resolve any issue reported through a method other than the ones mentioned above. If you can't switch to another channel, PULPIT Cab declines any responsibility.
          </p>
          <p>
            When you report a problem, PULPIT Cab will try to address it within 2 (two) working days and try to find a solution as quickly as possible. It is now made clear that problems are handled according to their seriousness.
          </p>
          <p>
            By agreeing to these User Terms and using the Service, You agree to defend, hold harmless, and act to protect Pulpit Cab, its affiliates, their licensors, and each of their officers, directors, other users, employees, attorneys, and agents from and against any and all claims, costs, damages, losses, liabilities, and expenses (including attorneys' fees and costs) resulting in legal actions from or connected with any of the following:
            <ul>
              <li>Your use or misuse of the Application or Service;</li>
              <li>Your violation of any provision of these User Terms;</li>
              <li>Your violation of any applicable law or regulation, whether or not specifically mentioned above.</li>
            </ul>
          </p>
          <p>
            If there is a conflict or disagreement between the provisions of this User Terms and any other agreement that You and PULPIT Cab have established, the terms of this User Terms shall control, unless an exception has been specifically agreed upon in writing and refers to the specific clause that is being altered under these User Terms.
          </p>
          <p>
            The laws of India govern these User Terms. The parties shall try to resolve any dispute, claim, or controversy amicably through negotiation and consultation at the offices of PULPIT Cab before resorting to litigation. Any dispute, claim, or controversy arising out of or relating to these User Terms or the breach, termination, enforcement, interpretation, or validity thereof or the use of the Site, the Service, or the Application (collectively, "Disputes") is subject to the arbitration provisions set forth below. The Arbitration and Conciliation Act, 1996, as modified from time to time, or in the event that the Arbitration and Conciliation Act, 1996 is no longer in effect, as per any law relating to arbitration in force at the time of such reference, shall apply to any dispute that cannot be settled internally between the parties after at least 30 (thirty) days of negotiations conducted in good faith. A single arbiter who has been jointly chosen by PULPIT and You will be referred to in this case. Bengaluru, Karnataka, shall be the location of the arbitration, unless PULPIT and You mutually agree otherwise in writing. Any dispute will fall within the sole jurisdiction of the courts in Bangalore, India, subject to the aforesaid.
          </p>

          <p>
            You hereby accept and acknowledge all the aforesaid terms and conditions in relation to the services provided by PULPIT, by selecting “I ACCEPT” and or by rendering the services offered by PULPIT Cab.
          </p>
          </section>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default RefundAndCancellation