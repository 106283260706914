import React from 'react'

const AboutApp = () => {
    return (
        <div className='about-app'>
            <div className='about-app__container'>
                <div className='about-app__heading'>About  Our <br/> Booking App</div>
                <ul className='about-app__list'>
                    <li className='about-app__list-item'>
                        <svg className='about-app__icon' aria-hidden="true">
                            <use href="sprite.svg#icon-check"></use>
                        </svg>
                        Convenience and Accessibility
                    </li>
                    <li className='about-app__list-item'>
                        <svg className='about-app__icon' aria-hidden="true">
                            <use href="sprite.svg#icon-check"></use>
                        </svg>
                        User-Friendly Interface
                    </li>
                    <li className='about-app__list-item'>
                        <svg className='about-app__icon' aria-hidden="true">
                            <use href="sprite.svg#icon-check"></use>
                        </svg>
                        Personalization and Preferences
                    </li>
                    <li className='about-app__list-item'>
                        <svg className='about-app__icon' aria-hidden="true">
                            <use href="sprite.svg#icon-check"></use>
                        </svg>
                        Special Offers and Discounts
                    </li>
                    <li className='about-app__list-item'>
                        <svg className='about-app__icon' aria-hidden="true">
                            <use href="sprite.svg#icon-check"></use>
                        </svg>
                        Notifications and Reminders
                    </li>
                    <li className='about-app__list-item'>
                        <svg className='about-app__icon' aria-hidden="true">
                            <use href="sprite.svg#icon-check"></use>
                        </svg>
                        Integrated Reviews and Ratings
                    </li>
                    <li className='about-app__list-item'>
                        <svg className='about-app__icon' aria-hidden="true">
                            <use href="sprite.svg#icon-check"></use>
                        </svg>
                        Environmental Impact
                    </li>
                </ul>
                <a href='https://play.google.com/store/apps/details?id=com.pulpit.pulpit_customer&pcampaignid=web_share&pli=1' target="_blank" rel='noreferrer'>
                <button className='about-app__button'>
                    Download app Now
                </button>
                </a>
            </div>
        </div>
    )
}

export default AboutApp