import React from 'react'

const DownloadSection = () => {
  return (
    <div className='downloadSection'>
        <img className='downloadSection__img downloadSection__img--1 ' src='/images/download-ep.png' alt='eclipse' ></img>
        <img className='downloadSection__img downloadSection__img--2 ' src='/images/download-ep.png' alt='eclipse' ></img>
        <div className='downloadSection__quote'>Get Pulpit on Your and start Riding</div>
        <div className='downloadSection__description'>A Private Limited is the most popular type of partnership India. The limited liabilityis, in fact, the only type of the company allowed by Companies.</div>
        <div className='downloadSection__buttons'>
          {/* <button className='downloadSection__button downloadSection__button--apple'>
            <a className='downloadSection__link' href='https://apps.apple.com/in/app/pulpit-supermarket-of-rides/id6444613022' target="_blank" rel='noreferrer'>
              <svg className='downloadSection__icon' aria-hidden="true">
                <use href="/sprite.svg#icon-appleinc"></use>
              </svg>
              <div className='downloadSection__text'>
                <span className='downloadSection__text-primary'>Download on the</span>
                <span className='downloadSection__text-secondary'>App Store</span>
              </div>
            </a>
          </button> */}
          <button className='downloadSection__button downloadSection__button--playstore'>
            <a className='downloadSection__link' href='https://play.google.com/store/apps/details?id=com.pulpit.pulpit_customer&pcampaignid=web_share&pli=1' target="_blank" rel='noreferrer'>
            
              <img className='downloadSection__icon' src="/images/icon-google-play.svg" alt='pulpit google play'></img>
              
              <div className='downloadSection__text'>
                <span className='downloadSection__text-primary'>GET IT ON</span>
                <span className='downloadSection__text-secondary'>GOOGLE PLAY</span>
              </div>
            </a>
          </button>
        </div>
    </div>
  )
}

export default DownloadSection
