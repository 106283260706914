import React, { useState, useEffect } from 'react';
import { links } from '../../components/data';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeNewsletter } from '../../store/SubScribe/SubScribeAction';
import { subscribeAction } from '../../store/SubScribe/SubScribeReducer';
import { toast } from 'react-toastify';

const Footer = () => {
    const [email, setEmail] = useState('');

    const { isSubmit, error,newsletter } = useSelector((state) => state.subscribe);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isSubmit) {
            toast.success(newsletter?.message||'Newsletter Subscribe Successfully', {
                position: 'bottom-right',
                style: { fontSize: '12px' }
            });
            setEmail(''); 
            dispatch(subscribeAction.clearSubmit());
        }
        if (error) {
            toast.error(newsletter?.message||'This email already exists!', {
                position: 'bottom-right',
                style: { fontSize: '12px' }
            });
            dispatch(subscribeAction.clearError());
        }
    }, [dispatch, isSubmit, error,newsletter]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(subscribeNewsletter({email}));
    };

    return (
        <div className='footer'>
            <div className='footer__upper'>
                <div className='footer__column footer__column--logo'>
                    <a href="/" className="footer__logo-link">
                        <img src="/images/logo-small.png" alt="MyCompany Logo - Cab Service" className="footer__logo" />
                    </a>
                    <button className='downloadSection__button downloadSection__button--playstore footer__download-button'>
                        <a className='downloadSection__link' href='https://play.google.com/store/apps/details?id=com.pulpit.travel_driver_pulpit&pcampaignid=web_share' target="_blank" rel='noreferrer'>
                            <img className='downloadSection__icon' src="/images/icon-google-play.svg" alt='pulpit google play'></img>
                            <div className='downloadSection__text'>
                                <span className='downloadSection__text-primary'>GET IT ON</span>
                                <span className='downloadSection__text-secondary'>GOOGLE PLAY</span>
                            </div>
                        </a>
                    </button>
                    <div className='footer__heading-secondary'>WE ARE AVAILABLE</div>
                    <div className='footer__text'>Mon-Sat: 9:00 am to 7:00pm</div>
                    <ul className='footer__social-list'>
                        <a href={links?.facebook} target="_blank" rel='noreferrer'>
                            <li className='footer__social-item'>
                                <svg className='footer__social-icon' aria-hidden="true">
                                    <use href="/sprite.svg#icon-facebook"></use>
                                </svg>
                            </li>
                        </a>
                        <a href={links?.twitter} target="_blank" rel='noreferrer'>
                            <li className='footer__social-item'>
                                <svg className='footer__social-icon' aria-hidden="true">
                                    <use href="/sprite.svg#icon-twitter"></use>
                                </svg>
                            </li>
                        </a>
                        <a href={links?.whatsapp} target="_blank" rel='noreferrer'>
                            <li className='footer__social-item'>
                                <svg className='footer__social-icon' aria-hidden="true">
                                    <use href="/sprite.svg#icon-whatsapp"></use>
                                </svg>
                            </li>
                        </a>
                        <a href={links?.instagram} target="_blank" rel='noreferrer'>
                            <li className='footer__social-item'>
                                <svg className='footer__social-icon' aria-hidden="true">
                                    <use href="/sprite.svg#icon-instagram"></use>
                                </svg>
                            </li>
                        </a>
                        <a href={links?.linkedin} target="_blank" rel='noreferrer'>
                            <li className='footer__social-item'>
                                <svg className='footer__social-icon' aria-hidden="true">
                                    <use href="/sprite.svg#icon-linkedin"></use>
                                </svg>
                            </li>
                        </a>
                    </ul>
                </div>
                <div className='footer__column footer__column--links'>
                    <div className='footer__heading-primary'>QUICK LINKS</div>
                    <ul className='footer__links-list'>
                        <li className='footer__links-item'>
                            <a href='/about-us' className='footer__page-link'>About us</a>
                        </li>
                        <li className='footer__links-item'>
                            <a href='/support' className='footer__page-link'>Support</a>
                        </li>
                        <li className='footer__links-item'>
                            <a href='/blogs' className='footer__page-link'>Blog</a>
                        </li>
                        <li className='footer__links-item'>
                            <a href='/business' className='footer__page-link'>Business</a>
                        </li>
                        <li className='footer__links-item'>
                            <a href='/partner' className='footer__page-link'>Partner</a>
                        </li>
                        <li className='footer__links-item'>
                            <a href='/get-app' className='footer__page-link'>Get App</a>
                        </li>
                    </ul>
                </div>
                <div className='footer__column footer__column--contact'>
                    <div className='footer__heading-primary'>CONTACT DETAILS</div>
                    <ul className='footer__contact-list'>
                        <li className='footer__contact-item'>
                            <div className='footer__heading-secondary'>PHONE NUMBER</div>
                            <div className='footer__contact-box'>
                                <div className='footer__contact-iconbg'>
                                    <svg className='footer__contact-icon' aria-hidden="true">
                                        <use href="/sprite.svg#icon-phone"></use>
                                    </svg>
                                </div>
                                <div className='footer__text'>(+91) {links?.phone}</div>
                            </div>
                        </li>
                        <li className='footer__contact-item'>
                            <div className='footer__heading-secondary'>EMAIL ADDRESS</div>
                            <div className='footer__contact-box'>
                                <div className='footer__contact-iconbg'>
                                    <svg className='footer__contact-icon' aria-hidden="true">
                                        <use href="/sprite.svg#icon-email"></use>
                                    </svg>
                                </div>
                                <div className='footer__text'>{links?.email}</div>
                            </div>
                        </li>
                        <li className='footer__contact-item'>
                            <div className='footer__heading-secondary'>OFFICE LOCATION</div>
                            <div className='footer__contact-box'>
                                <div className='footer__contact-iconbg'>
                                    <svg className='footer__contact-icon' aria-hidden="true">
                                        <use href="/sprite.svg#icon-location"></use>
                                    </svg>
                                </div>
                                <div className='footer__text'>305, CAPITOL ICON, <br></br>Sargasan, Gandhinagar,<br></br> Gujarat 382421</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='footer__column footer__column--newsletter'>
                    <div className='footer__heading-primary'>NEWSLETTER</div>
                    <div className='footer__newsletter-text'>Sign Up to get updates & news about us. Get Latest Deals from Walker's Inbox to your email.</div>
                    <form onSubmit={handleSubmit}>
                        <input
                            type='email'
                            placeholder='Enter your email'
                            className='input-email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button className='input-submit-button footer__subscribe-button'>SUBSCRIBE NOW</button>
                    </form>
                </div>
            </div>
            <div className='footer__lower'>
                <div className='footer__lower-text'>
                    Created with ❤ by the Pulpit Team
                </div>
                <div className='footer__lower-links'>
                    <a href='/privacy_policy' className='footer__lower-link'>Privacy Policy</a>
                    <a href='/terms_condition' className='footer__lower-link'> | Terms & Conditions</a>
                    <a href='/refund-cancellation' className='footer__lower-link'> | Refund & Cancellation</a>
                </div>
            </div>
        </div>
    );
};

export default Footer;