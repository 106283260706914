import React, { useState, useEffect } from 'react';

const BookCabForm = () => {
  const [formValues, setFormValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  });

  useEffect(() => {
    // Initialize form values from local storage if available
    const savedData = JSON.parse(localStorage.getItem('tripData'));
    if (savedData) {
      setFormValues({
        first_name: savedData.first_name || '',
        last_name: savedData.last_name || '',
        email: savedData.email || '',
        mobile_number: savedData.mobile_number || '',
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...formValues, [name]: value };
    setFormValues(updatedValues);

    // Retrieve current tripData from localStorage
    const currentTripData = JSON.parse(localStorage.getItem('tripData')) || {};

    // Update the tripData with the new form values
    const updatedTripData = {
      ...currentTripData,
      ...updatedValues,
    };

    // Save updated tripData back to localStorage
    localStorage.setItem('tripData', JSON.stringify(updatedTripData));
  };

  return (
    <div className="book-cab-form">
      <div className='book-cab-form__heading'>Traveller Information</div>
      <form className="book-cab-form__form">
        <div className='book-cab-form__box'>
        <div className="book-cab-form__wrapper">
          <input
            type="text"
            id="first_name"
            name="first_name"
            className="input-textarea"
            value={formValues.first_name}
            placeholder="First Name"
            onChange={handleChange}
            required
          />
        </div>
        <div className="book-cab-form__wrapper">
          <input
            type="text"
            id="last_name"
            name="last_name"
            className="input-textarea"
            placeholder="Last Name"
            value={formValues.last_name}
            onChange={handleChange}
            required
          />
        </div>
        </div>
        <div className='book-cab-form__box'>
        <div className="book-cab-form__wrapper">
          <input
            type="email"
            id="email"
            name="email"
            className="input-email"
            placeholder="Email"
            value={formValues.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="book-cab-form__wrapper">
          <input
            type="tel"
            id="mobile_number"
            name="mobile_number"
            className='input-phone'
            placeholder="Phone Number"
            value={formValues.mobile_number}
            onChange={handleChange}
            pattern="[0-9]{10}"
            maxLength="10"
            required
          />
        </div>
        </div>
      </form>
    </div>
  );
};

export default BookCabForm;