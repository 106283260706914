import React from 'react'

const MainAboutUs = () => {
    return (
        <div className='main-aboutus'>
            <div className='main-aboutus__container'>
            <div className='main-aboutus__box1'>
                    <div className='main-aboutus__content'>
                        <h1 className='primary__heading'>About <span className='color-secondary'>Pulpit</span>
                        </h1>
                        <p className='paragraph'>Pulpit Cab is a premium cab aggregator, providing a one-stop application solution for a variety of travel problems, spanning from local and outstation travel to rental car service. Being in the cab industry for the last 5 years, we continue to strive to deliver the reputation as one of the best companies in Gujarat.
                        </p>
                    </div>
                    <div className='main-aboutus__content'>
                        <h1 className='primary__heading'>Our <span className='color-secondary'>Vision & Mission</span>
                        </h1>
                        <p className='paragraph'>Pulpit Cab originated with a mission to solve the issues in the unorganized taxi industry in india. With this mission, Pulpit has a vision to develop a single platform by providing a one- stop solution for a variety of travel problems that is why we are supermarket of rides.
                        </p>
                    </div>
                </div>
                <div className='main-aboutus__box2'>
                    <img src='/images/googleReview.png' alt='pulpit mobile locai' className='main-aboutus__img'></img>
                </div>
            </div>
        </div>
    )
}

export default MainAboutUs