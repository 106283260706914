import React from 'react';
import Navbar from '../../layout/Navbar';
import Footer from '../../layout/Footer';

const TermsAndConditions = () => {
  return (
    <div>
      <Navbar></Navbar>
      <div className="terms-container">
        <h1>TERMS AND CONDITIONS</h1>

        <section>
          <h2>KEYWORDS AND OTHER SPECIFICATIONS</h2>
          <p><strong>“My profile”</strong> shall mean the account created by the user on the Application for availing the Services provided by Pulpit.</p>
          <p><strong>For purposes of this Agreement, "Account" refers to</strong> the account that the Customer establishes on the Application in order to make use of Pulpit's services.</p>
          <p><strong>The term "Additional Fee" refers to</strong> any toll duty, inter-state taxes, or other fees that may not be included in the Fare or may not have already been paid as a part of the Total Ride Fee but are owed to any third party or government authorities for the undertaking of the Ride in accordance with applicable law.</p>
          <p><strong>When we talk about "Applicable Laws,"</strong> we're referring to all of the statutes, enactments, acts of legislature or parliament, laws, ordinances, rules, by-laws, regulations, notifications, guidelines, policies, directions, directives, and orders that come from any governmental authority, tribunal, or court in India. These all fall under the umbrella of "Applicable Laws."</p>
          <p><strong>When we talk about the "Application,"</strong> we mean the mobile application known as "Pulpit customer application," which gets updated by pulpit on a regular basis.</p>
          <p><strong>“Subscription plan” shall mean</strong> the fee payable by the User to Pulpit for the Service i.e., for availing the application services offered by Pulpit.</p>
          <p><strong>"Convenience Fee" or "Access Fee" shall be</strong> the fee due by the Customer to Pulpit for the Service, which is defined as the fee for availing oneself of the technology services supplied by Pulpit, such as door to door service, wi-fi access, customer support, and cashless payment alternatives.</p>
          <p><strong>The term "Cancellation Fee" refers to</strong> the Fare and Convenience Fee that a Customer is required to pay in order to cancel a Ride in accordance.</p>
          <p><strong>“INcity” shall mean</strong> a user ready to go to any fixed station in the city.</p>
          <p><strong>“Going to” shall mean</strong> a user ready to go to any fixed location.</p>
          <p><strong>“Hourly” shall mean</strong> a user is available for rental, fixed hours, and kilometers.</p>
          <p><strong>“Outstation” shall mean</strong> a user is ready to go to any location.</p>
          <p><strong>“User/partner/riding partner/you”</strong> means a person who has an Account on the Application as a user.</p>
          <p><strong>A "City of Operation" is defined as</strong> a city in which Customers use the transportation services and Drivers provide them correspondingly.</p>
          <p><strong>A person who possesses an Account on the Application is referred to as a "Customer" or "You."</strong></p>
          <p><strong>"Driver" shall mean</strong> and include such individuals as may be evaluated, appointed, and trained by an operator associated with us to provide the transportation services on its behalf.</p>
          <p><strong>The term “Wallet" refers to</strong> any pre-paid instrument that may be utilized for the purpose of making payments.</p>
          <p><strong>When we talk about "Fare," we're referring to</strong> the amount of money, in Indian Rupees, that's listed on the Application as the fare that's due for a certain Ride that was driven by a Driver.</p>
          <p><strong>When we talk about "Fare," we're referring to</strong> the amount of money, in Indian Rupees, that's listed on the Application as the fare that's due for a certain Ride that was driven by a Driver.</p>
          <p><strong>When we talk about "Ride," we're referring to</strong> when the Customer travels in the Vehicle thanks to the Site's assistance.</p>
          <p><strong>When we talk about "Service(s)," we mean</strong> the facilitation of services by Pulpit, which can be done through the Application, by making a phone call to Pulpit's call center, or by making a reservation on the Site.</p>
          <p><strong>When we talk about the "Site," we're referring to</strong> the Application as well as the website https://pulpitCab.com/ that Pulpit runs.</p>
          <p><strong>In the event that the Customer's Vehicle breaks down, the term "Substitute Vehicle" refers to</strong> an alternative vehicle that has been provided to convey the Customer to his or her destination.</p>
          <p><strong>When we talk about "Total Ride Fee," we're referring to</strong> the Fare in addition to the Convenience Fee or Access Fee, the Cancellation Fee, the Additional Fee, and any taxes that may be payable at certain points in time.</p>
          <p><strong>"T&Cs" and "User Terms" are shorthand references to these Terms and Conditions for Customers.</strong></p>
          <p><strong>When used in this clause, "TPSP" refers to</strong> a third party service provider.</p>
          <p><strong>For the purposes of this section, "Vehicle" refers to</strong> a motor cab as that term is defined in the Motor Vehicles Act of 1988.</p>
        </section>

        <section>
          <h2>ELIGIBILITY AND ACCEPTABILITY</h2>
          <p>Only when you meet all of the requirements below will you be "Eligible" to utilize the Services:</p>
          <ul>
            <li>You are at least 18 (eighteen) years old.</li>
            <li>In accordance with the applicable laws, you are competent to enter into a contract.</li>
            <li>If the use of the Service or the capacity to engage into agreements like these User Terms is restricted due to age or where You live, You must adhere to such restrictions.</li>
          </ul>
        </section>

        <section>
          <h2>REGISTRATION AND PROFILE BUILDING</h2>
          <p>You are responsible for making sure that the Registration Data you give is true, accurate, current, and complete, as well as periodically updated. For any false, erroneous, outdated, or wrong registration data that You give, We shall have no liability.</p>
          <p>You alone are in charge of protecting the privacy of Your Registration Data, and you will be held accountable for all acts and transactions made through Your Account, whether they were started by you or someone else. Your Account cannot be given away, sold, or delegated to another person. We are not responsible for any damages You might suffer if someone else uses your password or account, either knowingly or unknowingly.</p>
          <p> If we have reason to think that the registration data or any other information associated with Your Account may be inaccurate, we have the right to suspend or cancel Your Account immediately and without notice.</p>
          <p>You have given erroneous or misleading information, Your account's security has been compromised in some way, or for any other reason We may deem appropriate or proper.</p>
          <p>Pulpit does not want you to, and you should not, send any confidential or proprietary information to pulpit on the Site or in any other way, unless otherwise is required by Applicable Laws, with the exception of the Registration Data or any other data submitted by You during the use of any other service offered through the Site ("Permitted Information"). By accepting these User Terms, you acknowledge that pulpit will not regard as secret or proprietary any information or materials that You or people acting on Your behalf give to pulpit that are not Permitted Information.</p>
          <p>It is your obligation to make sure that the application you download is the right one for your device. If You Download the Wrong Version of the Application for Your Mobile Device or If You Do Not Have a Compatible Mobile Device, We Are Not Responsible. If You Use the Service or Application with an Unauthorized or Incompatible Device, We Reserve the Right to Terminate the Service and Your Use of the Application.</p>
          <p> If You Cannot Access Your Account, please contact us at support@pulpitCab.com And Make A Written Request For Your Account To Be Blocked. Prior to the passing of 72 (seventy-two) hours after You have filed a written request to block Your Account, We will not be responsible for any unauthorized transactions conducted using Your Account. We further disclaim all responsibility in the event of a Force Majeure Event.
            INCLUDES-</p>
          <p></p>
          <ul>
            <li>That PULPIT can change and update the existing policy at any time.</li>
            <li>That PULPIT is strict about data policy. The users cannot share any application data with anyone</li>
            <li>If the user is found guilty or involved in any illegal activity then PULPIT will not be responsible for any such activity.</li>
            <li>SOS services will be available in the application for both users.</li>
            <li>Parivahan Seva shall be available on the application at specific subscription plans for the user. PULPIT will not charge anything to the parivahan Seva.</li>
          </ul>

        </section>

        <section>
          <h2>ASSISTANCE AND SERVICES</h2>
          <p>You are able to use the various services provided by pulpit and/or a third party through the Site.</p>
          <p>The Services enable You to make a request to a Driver on the PULPIT network through the PULPIT network. Each request for Service may be accepted or rejected at the Driver's sole and absolute discretion. If a request is accepted by a Driver, PULPIT will inform you about the trip; including the Driver's name, vehicle license number, phone number, and any other information pulpit deems appropriate.</p>
          <p>By using the Application or the Service, You also agree that:</p>
          <ul>
            <li>You will only use the Service or download the Application for Your own personal use and will not resell or assign it to a third party;</li>
            <li>You will not use an account that is subject to any rights of a person other than You without the necessary authorization;</li>
            <li>You will not use the Service or Site for illegal purposes; and</li>
            <li>You will not attempt to harm the Service, Site, or our network While using the Site or Service.</li>
            <li>You will Obey All Applicable Law from Your Country of Domicile and Residence and the Country, State and/or City in Which You Are Present; and</li>
            <li>Be Aware Of And Obey The Information Technology Act, 2000 And The Rules, Regulations And Guidelines ThereUnder.</li>
          </ul>
          <p>In the event that any of the aforementioned conditions are not met, pulpit reserves the right to immediately cancel the Service and the usage of the Application. Additionally, pulpit will save the data you supply or record your calls in order to get in touch with you for all service-related issues. Any changes to the information you have provided must be immediately notified to pulpit.</p>
          <p>In the event that any of the aforementioned conditions are not met, pulpit reserves the right to immediately cancel the Service and the usage of the Application. Additionally, pulpit will save the data you supply or record your calls in order to get in touch with you for all service-related issues. Any changes to the information you have provided must be immediately notified to pulpit.</p>
          <p>In order to carry out PULPIT's obligations under these customer terms and conditions, pulpit will be free to process and transfer your information as and when it sees fit. PULPIT may also store or transfer your information to a server outside of India or the country in which you are located.</p>
          <p>You agree to give PULPIT a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through several tiers) right to use any copyright, publicity, database, or other rights you may have in your information, in any media now known or not currently known, with respect to Your information.</p>
          <p>You authorize PULPIT to share your information, as well as any other information that is allowed, with third parties.</p>
          <p>You acknowledge and agree that PULPIT may share any information you submit with other parties in order to facilitate the provision of certain value-added services offered to you by such third parties and/or by pulpit. You expressly agree that your registered data may be used by PULPIT and/or third parties to contact you regarding value-added services. You acknowledge and agree that you will not hold PULPIT liable for any such communications you receive from third parties, nor will any such communications constitute spam, unsolicited communication, or a breach of your registration with the national do-not-call registry.</p>
          <p>PULPIT will have the right to collaborate with any institution that offers services that are identical to or similar to those described in this document, whether through a joint venture or another arrangement. In this situation, PULPIT shall offer you service either jointly or separately with the other party or parties in a joint venture, based on the modality and method that may be developed. You hereby grant such a tie-up your unreserved approval and assent. The terms and conditions included below will, mutatis mutandis, become applicable in respect of such a tie-up arrangement.</p>
          <p>You must abide by the following if PULPIT gives you any Vehicle to test as part of the Services, including but not limited to PULPIT CARS ("pulpit CARS"): If You agree that PULPIT may charge you for any damage caused to the Vehicle while being used by You; (ii) You will use the test Vehicle only for the purpose of testing and shall not exceed the predetermined time/distance. (iii) You will not use the test Vehicle only for the purpose of testing and shall not exceed the predetermined time/distance.</p>
          <p>PULPIT, using its best efforts and sole discretion, may arrange for a Substitute Vehicle to complete Your Ride to Your destination in the event of a breakdown of the Vehicle that is beyond repair before completion of the Ride. However, the availability of the substitute vehicle will determine how it is arranged.You understand and accept that the substitute vehicle may not be the exact same model as the one you originally reserved from the site.</p>
          <p>PULPIT disclaims all responsibility and liability for any delays or losses you may incur as a result of the failure of your vehicle or a substitute vehicle.</p>
        </section>
        <section>
          <h2>BOOKING AND CONFIRMATION</h2>
          <p>
            Pulpit will proceed to confirm or deny the booking based on the availability of Vehicles at the pickup time, which will be communicated to You via an application notification, after receiving the booking request from You in the manner described above. If the reservation is confirmed, You must check the information, including but not limited to the pick-up time and location, and if there is an error, You must contact us right away by calling our call center.
          </p>
          <p>
            You are responsible for any delays you experience as a result of failing to check the confirmation SMS or email or failing to quickly notify Pulpit of the inaccurate information.
          </p>
          <p>
            Only a small group of customers will be qualified to use the Service through pulpit’s "Guest Booking" function. These clients will be subject to the terms and conditions outlined here as well.
          </p>
        </section>

        <section>
          <h2>PAYMENT AND BILLING</h2>
          <p>
            Pulpit shall only charge a subscription and Fee shall be payable by the user to Pulpit by every 30 days and shall be informed to you before ending the subscription.
          </p>
          <p>
            Other fares, tolls, taxes, allowances and cancellation fees, etc. will be subject to partner and ride-sharing partners.
          </p>
          <p>
            Users shall choose to pay for the available subscription plans. Which can be updated by Pulpit at any point of time as per the internal management.
          </p>
          <p>
            If you choose to use these services, TPSP will occasionally charge you the fees it may advise you of.
          </p>
          <p>
            You will be required to pay the Additional Fee, which will be included in the receipt of the Total Ride Fee, in accordance with clause.
          </p>
          <p>
            You will be obliged to pay the Cancellation Fee, which will be included in the receipt for the Total Ride Fee.
          </p>
          <p>
            In addition to the Total Ride Fee, you might have to pay any extra fees you incurred during the ride in cash, such as an Additional Fee that isn't covered by the receipt for the Total Ride Fee.
          </p>
          <p>
            The Driver or Pulpit will collect the Total Ride Fee from You at the conclusion of the Ride.
          </p>
          <p>
            Pulpit will give you a receipt for the Total Ride Fee you owe at the end of the ride, but you can also request separate invoices from the TPSPs for the Fare and Additional Fee as well as the Convenience Fee or Access Fee. From our Support page, you may submit a request for a copy of the bills.
          </p>
          <p>
            You are responsible for paying all relevant taxes related to the fare, convenience fee, additional fee, or cancellation fee to the TPSPs or pulpit, as per the case.
          </p>
          <p>
            All payment-related disputes must be settled between You and the Payment Processor, unless they are the result of a mistake or flaw in the Site. Any unlawful use of Your E-Wallet while or after using the Services on the Site is not pulpit's responsibility.
          </p>
        </section>

        <section>
          <h2>CONSUMER TERMS</h2>
          <p>
            You must not consume alcohol or smoke in the vehicles, act inappropriately around the driver, cause the driver to become distracted, or break any applicable laws. If it is discovered that You are engaging in the aforementioned behavior,
          </p>
          <p>
            In addition to having the right to cancel the Ride, you will be required to pay us a fine. After the Ride is over, if You still haven't paid the fine, we may, in our discretion, take whatever legal action may be available to us. If you don't pay the fine or behave badly, as the case may be, you'll also get blacklisted, and Pulpit may close your account as a result.
          </p>
        </section>

        <section>
          <h2>CONSUMER ADMINISTRATION</h2>
          <p>
            Any problems, thoughts, ideas, questions, or comments about our services should be sent to us at the email.
          </p>
          <p>
            If you book a Ride through our App, you will have to rate the Ride when it's over. You agree to be fair, honest, and not mean when you leave feedback, comments, testimonials, or reviews on or about the Rides or Services.
          </p>
          <p>
            Any problem must be reported within seven days of it happening, or it won't be fixed.
          </p>
          <p>
            Pulpit will only try to fix problems that are reported through channels other than the ones above. Pulpit doesn't have to pay if you can't get back on other channels.
          </p>
        </section>

        <section>
          <h2>EMERGENCY SERVICES TO CUSTOMER</h2>
          <p>
            By agreeing to these User Terms and pressing the SOS button on the Pulpit App, you agree that Pulpit can use its best efforts to help you in safety incidents, emergencies, or times of distress, according to pulpit's internal policies on emergency response.
          </p>
          <p>
            Pulpit could take steps to fix the problem, such as, but not limited to, the following:
          </p>
          <ul>
            <li>When you press the SOS button and report a safety incident, a Pulpit representative may escalate the situation internally, work with third-party security service providers to help you, or send response teams to your location.</li>
            <li>Pulpit may tell government authorities, like the local police station, about the incident and ask for their help. Pulpit may also give these authorities your personal information, like your exact GPS location, email address, and phone number, so they can help you in the best way possible.</li>
            <li>Pulpit could hire outside security providers to help You and Pulpit deal with the situation. To do this, Pulpit may share your personal information, such as your exact GPS location, registered email ID, and mobile number, with third parties, like a security services provider, so that they can take the right steps to help you and end the situation.</li>
          </ul>
          <p>
            In addition to the above, you may get phone calls from a Pulpit representative and/or the appropriate response team when you press the SOS button and while the problem is being fixed. This will not be seen as a violation of TRAI rules because the calls will be made to make sure you are safe and secure.
          </p>
          <p>
            Under this clause, you give Pulpit your clear permission to do all of the above, as well as any other things that may be needed to help an emergency response go smoothly.
          </p>
          <p>
            Pulpit won't be responsible for any problems with the service it gives you, even if it's not as good as it could be. This clause doesn't stop you from getting help from anyone else, though.
          </p>
        </section>
        <section>
          <h2>Indemnification</h2>
          <p>
            By accepting these User Terms and using the Service, You agree to defend, indemnify, and hold harmless Pulpit, its affiliates, their licensors, and each of its officers, directors, other users, employees, attorneys, and agents from and against any and all claims, costs, damages, losses, liabilities, and expenses (including attorneys' fees and costs) arising out of or in connection with:
          </p>
          <ul>
            <li>Your violation or breach of any term of these User Terms or any Applicable Terms;</li>
            <li>Your violation of any third party's rights, including those of the TPSPs; or</li>
            <li>Your improper use or misuse of the Application or Service.</li>
          </ul>
        </section>

        <section>
          <h2>Liability</h2>
          <p>
            The information, recommendations, and/or Services given on or through the Site, the Application, and the Pulpit call center are for informational purposes only and do not constitute advice. Pulpit will use reasonable efforts to maintain the accuracy and currency of the Site and its information but does not guarantee that the Site is free of mistakes, flaws, malware, or viruses, nor that the Site is correct, current, or accurate.
          </p>
          <p>
            Pulpit shall not be liable for you missing trains/flights/events or delays due to the fact that the Service is dependent on a variety of external variables. You must schedule Your Ride after considering check-in time, traffic, weather conditions, political rallies, natural disasters, road barricades, car breakdowns, and other unanticipated delays.
          </p>
          <p>
            If the Vehicle is more than 30 minutes late to arrive at the pickup place, Pulpit will do its best to connect you with the driver assigned to Your Ride.
          </p>
          <p>
            Pulpit shall not be liable for any damages resulting from the use or inability to use the Site, including damages caused by improper use, errors, network issues, or malware.
          </p>
          <p>
            You are solely responsible for Your belongings and luggage. If items are lost during the trip, Pulpit will make a "best-effort" attempt to recover them, but is not responsible for their loss or damage. If recovered, you will be notified where to collect your items.
          </p>
          <p>
            Pulpit does not guarantee the continued availability of its Service and shall not be held accountable or liable in any way for the same.
          </p>
          <p>
            Pulpit shall not be liable for any direct, indirect, punitive, incidental, special, or consequential damages or any damages whatsoever, whether based on contract, tort, negligence, strict liability, or otherwise, even if advised of the possibility thereof.
          </p>
          <p>
            To the maximum extent permitted by law, Pulpit's liability arising out of these User Terms or the services rendered shall not exceed Rs. 1000/- (Rupees One Thousand only).
          </p>
          <p> In the event that Applicable Law prohibits the exclusion of specific guarantees or the limitation or exclusion of liability, the breadth and duration of such warranty exclusions and the extent of pulpit's liability shall be the least allowable by Applicable Law.</p>
        </section>

        <section>
          <h2>Application License</h2>
          <p>
            Pulpit grants You a limited, revocable, non-exclusive, non-transferable, and non-sub-licensable license to download and install a copy of the Application on a single mobile device that You own or control, run such copy of the Application solely for Your own personal use, and use the Site, subject to Your compliance with these User Terms.
          </p>
          <p>
            You shall not:
            <ul>
              <li>License, sublicense, sell, resell, transfer, assign, distribute, or otherwise commercially exploit the Service or Site;</li>
              <li>Modify or make derivative works based upon the Service or Application;</li>
              <li>Attempt to reverse engineer or access the Site to create a competitive product;</li>
              <li>Launch automated scripts that overload the system, or</li>
              <li>Send spam or harmful content through the Service.</li>
            </ul>
          </p>
          <p>
            Pulpit reserves the right to remove content or terminate access if any violations occur.
          </p>
        </section>
        <section>
          <h2>Content Posted by Customers</h2>
          <p>
            Pulpit may permit posting by You of any notes, messages, emails, photos, drawings, profiles, opinions, ideas, images, videos, audio files, or other materials or information on the Site ("Posted Content"). You guarantee that the Posted Content complies with all posted content regulations and that You have obtained all permissions and consents necessary to upload the Posted Content. In no way will Pulpit be held accountable for or endorsing the Posted Content.
          </p>
          <p>You certify that you won't do the following while publishing posted content:</p>
          <ul>
            <li>
              Publishing, posting, uploading, disseminating, or otherwise making available any offensive, obscene, indecent, or unlawful topic, blasphemous, pornographic, libellous, invasion of another person's privacy, hateful, or racially or ethnically objectionable material or information. Unless You Own Or Control The Rights Thereto or Have Acquired All Necessary Consents, You May Not Upload Files That Contain Software Or Other Material Protected by Intellectual Property Laws (Or By Rights of Privacy Or Publicity).
            </li>
            <li>
              Posting files that are corrupted, contain viruses, or otherwise contain software that could impair another person's computer's functionality.
            </li>
            <li>
              Promote the sale or purchase of any products or services for any commercial purpose, unless the Site expressly permits such messaging.
            </li>
            <li>Run or distribute polls, giveaways, pyramid schemes, or chain letters.</li>
            <li>
              Download any file that was provided by another user and that you know, or should know, cannot be disseminated legally in this way.
            </li>
            <li>
              Falsify or remove any author attributions, legal or other proper notices, or exclusive designations or labels of the origin or source of software or other content present in a file that has been uploaded.
            </li>
            <li>
              Lie or mislead the recipient regarding the source of a message or convey any material that is blatantly insulting or ominous in nature.
            </li>
            <li>Prevent any other user from utilizing and benefiting from the Site.</li>
            <li>
              Break any codes of conduct or other rules that could be relevant for a specific piece of posted content.
            </li>
            <li>
              Harvest or otherwise gather data, including email addresses, about individuals without permission.
            </li>
            <li>
              Endangers India's unity, integrity, defense, security, or sovereignty; friendly relations with other countries; or public order; or encourages the commission of any crime that is punishable by law; obstructs the investigation of any crime; or disparages any other country.
            </li>
            <li>
              Break any applicable laws or rules, such as the Information Technology Act of 2000 and the rules, regulations, and policies made under it.
            </li>
          </ul>
        </section>

        <section>
          <h2>Intellectual Property Ownership</h2>
          <p>
            PULPIT (and its licensors, as applicable) shall own all right, title, and interest, including all related intellectual property rights.
          </p>
          <p>The Site, Application, product, service, and any ideas, suggestions, requests for enhancements, feedback, recommendations, or other offerings;</p>
          <p>
            Text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork, and computer code; or
          </p>
          <p>
            Other information provided by You. The rights to any third-party trademarks that may appear on our website or application are exclusively reserved for their registered owners. You must obtain express written consent from the owner of any third party's intellectual property before using it in any way.
          </p>
          <p>
            These User Terms do not represent a sale and do not grant You any ownership interests in or ties to the Site, the Application, the Service, or any PULPIT-owned intellectual property. Any legal infractions and intellectual property rights infringements resulting from the use of the Services or the Site/Application shall exclusively be your responsibility.
          </p>
          <p>
            Information that PULPIT has consciously made accessible for download from the Site may be used by you, provided that you:
          </p>
          <ul>
            <li>
              Use such information only for Your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media;
            </li>
            <li>
              Use such information only for such purpose and do not remove any proprietary notice language in all copies of such documents and make no modifications to the information; or
            </li>
            <li>
              Do not make any additional representations or warranties relating to such information.
            </li>
          </ul>
        </section>
        <section>
          <h2>TERMINATION OF LICENSE AGREEMENT</h2>
          <p>
            The agreement between PULPIT and You is everlasting in nature when
            downloading the Application and for each Service booked through the
            Site, until explicitly canceled.
          </p>
          <p>
            You have the right to end the agreement at any time by deleting Your
            Account, which stops You from using the Site. By following the
            instructions on the site, you can close Your Account at any time.
          </p>
          <p>
            PULPIT is entitled to terminate the agreement at all times and with
            immediate effect (by disabling Your use of the Site and the Service)
            if You: (a) violate or breach any term of these User Terms, or (b) in
            the opinion of pulpit, misuse the Application or the Service. PULPIT
            is not obliged to give notice of the termination of the agreement in
            advance. After termination pulpit will give notice thereof in
            accordance with these User Terms.
          </p>
          <p>
            Termination of this agreement will not prejudice accrued rights of
            either pulpit or You.
          </p>
          <p>
            If this agreement is terminated, neither PULPIT nor You will have any
            accrued rights affected.
          </p>
          <p>
            The provisions of clauses (Indemnification), (Liability), (Application
            License), (Contents posted on Site/ Application), (Intellectual
            Property Ownership), (Term and Termination), , and any other clauses
            which by their terms are intended to survive termination, shall
            survive the expiration or termination of these User Terms.
          </p>
        </section>

        <section>
          <h2>CONFLICT</h2>
          <p>
            Unless the exception has been expressly agreed to in writing by making
            reference to the relevant Clause sought to be modified under this User
            Terms, the terms of the User Terms shall prevail in the event of any
            conflict or inconsistency between this User Terms and any other
            agreement executed between You and PULPIT.
          </p>
        </section>
        <section>
          <h2>DISCLAIMER</h2>
          <p>
            You agree that PULPIT does not in any way offer transportation services, but rather serves as an electronic platform for the aggregation of vehicles. You should not use any Drivers whose services PULPIT endorses, advertises, counsels, or recommends. Additionally, PULPIT makes no promises or guarantees regarding the conduct, deeds, or information of users posted on the website.
          </p>
        </section>

        <section>
          <h2>Warranties</h2>
          <p>
            You should not take any statement of warranty as a warranty from Us since We do not authorize anyone to speak on Our behalf.
          </p>
        </section>

        <section>
          <h2>Liabilities</h2>
          <p>
            PULPIT and its representatives, officers, employees, agents, and contractors are not responsible for any loss, damage, claim, expense, cost, or responsibility resulting directly or indirectly from Your use or inability to use the Service or the Site, or from Your reliance on either.
          </p>
          <p>
            Whether due to PULPIT's fault or the negligence of another person, you should not rely on the Service or the information on the Site.
          </p>
        </section>

        <section>
          <h2>Site and Application "As Is"</h2>
          <p>
            Without any express or implied warranties of any kind, including without limitation warranties of title or implied warranties of merchantability or fitness for a particular purpose, this site, the application, and all material on the site and the application are supplied "as is." By using the Site and/or Application, you agree that doing so is at your own risk, that PULPIT is not liable for any damages of any kind arising from your access to or use of the Site and/or Application, and that PULPIT will not be responsible for any errors or omissions in the content of the Site and/or Application.
          </p>
        </section>

        <section>
          <h2>Illustrative Content</h2>
          <p>
            The Site and/or Application solely use all images, audio, video, and text for illustrative reasons. If there are any models, actors, or items in the photographs, they are not in any way endorsing the services. Any resemblance between any substance and someone or anything else is coincidental and/or accidental.
          </p>
        </section>

        <section>
          <h2>Insurance Disclaimer</h2>
          <p>
            PULPIT doesn't work in the insurance industry and doesn't offer any insurance-related services. In accordance with group travel insurance policies obtained from insurance providers, PULPIT has facilitated the supply of insurance services for the benefit of Customers, serving as the group manager of the policy.
          </p>
          <p>
            Any benefits from using the insurance services offered by the insurance companies, including coverage, claims, and settlements, are not guaranteed or promised by PULPIT with regard to the insurance policy or insurance company. Any insurance claim or policy servicing that is brought up through the Application is still a matter between the Customer and the Insurance Company, and PULPIT will not be held liable for any party's actions. It should be noted that the relevant insurance company, not PULPIT, will issue the insurance policy number, claim certificate, or invoice for the insurance premium or charge that was paid by the customer.
          </p>
          <p>
            PULPIT is not liable for any difficulties encountered in getting tax invoices or in submitting insurance claims. PULPIT expressly disclaims all responsibility for the insurance company's services. Please get in touch with the insurance provider if you have any concerns or problems.
          </p>
        </section>

        <section>
          <h2>Modification of Service and User Terms</h2>
          <p>
            PULPIT maintains the right, in its sole discretion, to change, suspend, block, discontinue, or restrict your use of all or any features of the Service or Application at any time, as well as to amend or replace any of these User Terms in whole or in part.
          </p>
          <p>
            PULPIT is not obligated to let you know when these User Terms change. The updated User Terms will be posted on the website. You must frequently visit the Site in order to view the most recent User Terms.
          </p>
          <p>
            By checking the "Last Updated" legend above, you can find out when PULPIT last changed the User Terms. It shall be Your obligation to periodically review these User Terms for modifications. Before using the Site and the Services again, PULPIT may demand that You express Your agreement to the amended User Terms in a specific way. If no such additional consent is requested, Your continuing use of the Site after the User Terms have been modified will be deemed Your acceptance of the modifications.
          </p>
          <p>
            The most recent version of the User Terms posted on the Site at the time of such use shall govern your use of the Site and the Services.
          </p>
        </section>

        <section>
          <h2>Assignment</h2>
          <p>
            You may not transfer your rights under these User Terms without first receiving PULPIT’s explicit consent. PULPIT is free to transfer any affiliate rights under the User Terms.
          </p>
        </section>

        <section>
          <h2>Call and SMS Policy</h2>
          <ul>
            <li>To verify a user's phone number during account creation.</li>
            <li>To communicate with their riders regarding pickup location or any other pertinent information.</li>
            <li>To communicate with their users regarding trip details, fare, or any other trip-related information.</li>
            <li>Prohibit the use of call and SMS permissions for purposes such as marketing or data collection.</li>
            <li>Implement security measures to protect user data with encryption and authentication protocols.</li>
            <li>Ensures that all calls and SMS messages are initiated by the user, and that the app does not access or use call and SMS data for purposes other than those disclosed to the user.</li>
            <li>Use call and SMS data solely for the purpose of providing and improving their transportation services, and for enhancing safety and security for both riders and drivers.</li>
          </ul>
        </section>

        <section>
          <h2>Changes and Modifications</h2>
          <p>
            We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page.
          </p>
        </section>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default TermsAndConditions;