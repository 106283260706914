const testimonial = [
    {
        name: "Krupa Patel",
        review: "Pulpit Cabs is cheaper than normal taxis, and I like that. Prices are clear, and sharing rides saves my money. The drivers come quickly, and I’ve never had a cancellation problem. I highly recommend it.",
        image: "/images/Testimonial/Krupa.jpeg",
        topic: "Great Service and Affordable",
        rating: 4.5
    },
    {
        name: "Monika Darji",
        review: "As a woman traveling alone, I feel safe with this service. The emergency button and tracking make me feel better. The drivers are polite, and the cars are comfortable. I’ve had a good experience.",
        image: "/images/Testimonial/monika.jpeg",
        topic: "Safe and Comfortable",
        rating: 5
    },
    {
        name: "Divyraj Rathod",
        review: "I use Pulpit Cabs every day, and it's really easy. The app works well, I can see my ride on the map, and the drivers are usually nice. It's made getting around the city much easier.",
        image: "/images/Testimonial/Divyraj.jpeg",
        topic: "Reliable and Convenient",
        rating: 5
    },
    {
        name: "Yakin Dave",
        review: "I left my wallet in the car and called customer service. They contacted the driver fast, and I got my wallet back! Great help and quick response.",
        image: "/images/Testimonial/Yakin.jpeg",
        topic: "Quick Response During Emergencies",
        rating: 4
    },
    {
        name: "Jalpa Ratanpara",
        review: "I use Pulpit Cabs for work trips, and it’s very professional. The drivers are on time, and the cars are clean. The app is easy to use, and I like that I can track my ride in real time.",
        image: "/images/Testimonial/Jalpa.jpeg",
        topic: "Good and Reliable Service",
        rating: 5
    }
];

const mediaCoverage = [
    {
        image: "/images/mediaCoverage/1.png"
    },
    {
        image: "/images/mediaCoverage/2.png"
    },
    {
        image: "/images/mediaCoverage/3.png"
    },
    {
        image: "/images/mediaCoverage/4.png"
    },
    {
        image: "/images/mediaCoverage/5.png"
    },
    {
        image: "/images/mediaCoverage/6.png"
    }
];

const trustedByTheseCompanies = [
    {
        image: "/images/trustedCompany/1.png"
    },
    {
        image: "/images/trustedCompany/2.png"
    },
    {
        image: "/images/trustedCompany/3.png"
    },
    {
        image: "/images/trustedCompany/4.png"
    },
    {
        image: "/images/trustedCompany/5.png"
    }
];

const links ={
    "googlePlayDriver":"https://play.google.com/store/apps/details?id=com.pulpit.travel_driver_pulpit&pcampaignid=web_share",
    "googlePlayRider":"https://play.google.com/store/apps/details?id=com.pulpit.pulpit_customer&pcampaignid=web_share",
    "instagram":"https://www.instagram.com/pulpit_Cab/",
    "facebook":"https://www.facebook.com/PulpitCab",
    "youtube":"https://www.youtube.com/@pulpit_Cab",
    "twitter":"https://x.com/Cabpulpit?s=11&t=SzNp7wTtlLXm6dgy39PLQA",
    "whatsapp":"https://wa.me/8052080501",
    "linkedin":"https://www.linkedin.com/company/pulpit-Cab/",
    "phone":"80520 80501",
    "email":"info@pulpitCab.com",
}

const services = [
    {
        name: "Local Services",
        des: "Convenient rides within the city for daily travel needs.",
        image: "/images/services/local.png"
    },
    {
        name: "Rental Services",
        des: "Flexible car rentals for hours or days, perfect for personal use.",
        image: "/images/services/rental.png"
    },
    {
        name: "OutStation Services",
        des: " Comfortable long-distance travel for out-of-city trips.",
        image: "/images/services/outstation.png"
    },
]

const team = [
    {
        image: "/images/team/burhanbhai.png",
        name: "Burhan Dahodwala",
        role: "Operation Manager"
    },
    {
        image: "/images/team/Shobhit.png",
        name: "Shobhit Gupta",
        role: "Software Engineer"
    },
    {
        image: "/images/team/Devarsh.jpeg",
        name: "Devarsh Ukani",
        role: "Software Engineer"
    },
    {
        image: "/images/team/Man.jpeg",
        name: "Man Prajapati",
        role: "Web Developer"
    },
    {
        image: "/images/team/rohit.png",
        name: "Rohit",
        role: "Sales"
    }
];


export { testimonial, mediaCoverage, trustedByTheseCompanies, links, services, team }