import React, {useState} from 'react'
import OutstationForm from './OutstationForm';
import LocalForm from './LocalForm';
import RentalForm from './RentalForm';

const Form = () => {

    const [type, setType] = useState("outstation");
    
    const changeType = (value)=>{
        setType(()=>{
            return value;
        })
    }
    const randomNumber = Math.floor(Math.random() * 60) + 1;

    return (
        <div className='form'>
            <div className='form__options'>
                <div className={type==='outstation'?'form__option--active':'form__option'} onClick={()=>{changeType('outstation')}}>
                    <svg className='form__option-icon' aria-hidden="true">
                        <use href="sprite.svg#icon-outstation"></use>
                    </svg>
                    <span className='form__option-text'>OutStation</span>
                </div>
                <div className={type==='rental'?'form__option--active':'form__option'} onClick={()=>{changeType('rental')}}>
                    <svg className='form__option-icon' aria-hidden="true">
                        <use href="sprite.svg#icon-rental"></use>
                    </svg>
                    <span className='form__option-text'>Rental</span>
                </div>
                <div className={type==='local'?'form__option--active':'form__option'} onClick={()=>{changeType('local')}}>
                    <svg className='form__option-icon' aria-hidden="true">
                        <use href="sprite.svg#icon-local"></use>
                    </svg>
                    <span className='form__option-text'>Local</span>
                </div>
            </div>
            {type==='outstation'&&<OutstationForm randomNumber={randomNumber}/>}
            {type==='local'&&<LocalForm/>}
            {type==='rental'&&<RentalForm randomNumber={randomNumber} />}
        </div>
    )
}

export default Form