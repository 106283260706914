import React, { useEffect, useState } from 'react';

const BookCabHeader = () => {
  const [tripData, setTripData] = useState(null);

  useEffect(() => {
    // Step 1: Retrieve tripData from localStorage
    const savedTripData = JSON.parse(localStorage.getItem('tripData'));
    if (savedTripData) {
      setTripData(savedTripData);
    }
  }, []);

  const trip = ["Local","Rental","Outstation Oneway", "Outstation Round"]
  return (
    <div className='bookCabHeader'>
      <div className='bookCabHeader__container'>
        <div className='bookCabHeader__title'>Review Booking:</div>
        {tripData ? (
          <div className='bookCabHeader__details'>
            {/* Step 2: Display values from tripData */}
            <div className='bookCabHeader__details-item'>
              {tripData?.pickup_city} {tripData?.trip_type!==2 && `- ${tripData?.drop_city}`}
            </div>
            <div className='bookCabHeader__details-item'>
             {trip[tripData?.trip_type+tripData?.round_trip]} Pickup: {tripData?.trip_date} at {tripData?.trip_time}
            </div>
          </div>
        ) : (
          <div className='bookCabHeader__details'>
            No trip data available.
          </div>
        )}
      </div>
    </div>
  );
};

export default BookCabHeader;