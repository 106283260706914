import React from 'react'
import Slider from '../../../components/Slider'
import TeamCard from '../../../components/Cards/TeamCard';
import { team } from '../../../components/data';

const Team = () => {
  return (
    <div className='team'>
        <div className='primary__heading'>Our Team</div>
        <div className='team__container'>
            <div className='team__img-box'>
                <img src='./images/team-rec-2.png' alt='green rectangle' className='team__img-1'></img>
                
                <img src='./images/CEO.png' alt='pulpit CEO' className='team__img-2'></img>
                
                <img src='./images/team-rec-1.png' alt='orange rectangle' className='team__img-3'></img>
            </div>
            <div className='team__content'>
                <div className='team__content-box'>
                   <div className='team__content-heading'>Yogesh Sharma</div>
                   <div className='team__content-subHeading'>Founder ,CEO</div>
                   <div className='team__content-para'>Founder at PULPIT CAB | Searching for brave mind to help us | Join Pulpit | About Coding , What We think it Can be Done | Organising the Cab Business</div>
                </div>
            </div>
        </div>
        <Slider cards={team} cardWidth={34} cardMargin={2} component={TeamCard}></Slider>
    </div>
  )
}

export default Team