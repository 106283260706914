import React from 'react';
import Navbar from '../../layout/Navbar';
import Footer from '../../layout/Footer';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <Navbar></Navbar>
      <div className="privacy-policies">
        <h1>PULPIT PRIVACY POLICIES</h1>
        <section>
          <h2>Initialization</h2>
          <p>
            You entrust us with your personal information when using Pulpit. We're
            committed to upholding that confidence. Helping you comprehend our
            privacy practices is the first step in doing that. This notice
            explains how we gather data. We advise reading this in conjunction
            with our privacy overview, which highlights important details
            regarding our privacy practices.
          </p>
        </section>

        <section>
          <h2>Data gathering and application</h2>
          <p>
            We guarantee that no other organization will use the information
            gathered via the usage of Pulpit's service. Pulpit is going to start
            with the city of Gujarat named Ahmedabad and Gandhinagar. Your data
            follows you wherever you go so that we can provide you with a
            seamless experience. Whether you are in your own country or somewhere
            else, we also respect your rights to privacy.
          </p>
        </section>

        <section>
          <h2>INFORMATION WE COLLECT FROM YOU</h2>
          <p>
            You could be asked to submit a specific piece of protected
            information. Throughout the Services, we may collect this information
            in a variety of ways and places, such as during the registration
            process and other occasions when you engage with us. You create a
            user profile when you register to utilize the Services. We will only
            ask you for Protected Information that is legally necessary for us to
            acquire in order to provide you with our services. The following data
            fields are currently accessible and can be requested:
          </p>
          <ul>
            <li>Full name</li>
            <li>Email</li>
            <li>Password</li>
            <li>Address</li>
            <li>Mobile phone Number</li>
            <li>Zip Code</li>
          </ul>
        </section>

        <section>
          <h2>WE COLLECT INFORMATION AS YOU USE OUR SERVICE AND ACCESS</h2>
          <p>
            We and our Third Party Service Provider may use a number of
            technologies to passively collect certain information whenever you
            visit or interact with the Usage Information in addition to any
            Protected Information or other information that you choose to provide
            to us. The browser you are using, the URL that directed you to our
            Services, all of the pages you visit there, the time of day, and
            other details may all be included in this Usage Information. We also
            gather the Device Identifier for the Device. Our systems recognize
            your Device by its Device Identifier, which is a number that is
            automatically assigned to your Device when it is used to access the
            Services.
          </p>
          <p>
            For quality control purposes, we may record calls and chat histories
            when making a reservation over the phone. As you use our Services,
            tracking information is gathered, including but not limited to
            geographic areas. During the ride, the driver's phone will submit
            your GPS locations to our servers. If you include emergency contact
            information on your account or in the mobile application, we will
            gather and store it so we can share your ride details with that
            person in case of an emergency. If you recommend a friend to use our
            services, we will gather and store their contact information so we
            can reach out to them and tell them about our offerings. We will
            collect and store the contact information from your contact list if
            you use your account to book a cab ride for someone else from your
            contact list using the Services. We will also communicate the ride
            details with the chosen contact.
          </p>
        </section>

        <section>
          <h2>INFORMATION COLLECTED THROUGH DEVICE APPLICATION</h2>
          <p>
            We may, from time to time, supplement the information we collect
            about you through our website or Mobile Application or Services with
            outside records from third parties.
          </p>
          <h3>INFORMATION COLLECTED BY MOBILE APPLICATIONS</h3>
          <p>
            The Mobile Application is primarily used to offer Our Services. To
            enable us to provide you with software updates, product support, and
            other services (if any) related to such Mobile Phone applications, we
            may collect and use technical data and other relevant information,
            including but not limited to technical information about your device,
            system and application software, and peripheral devices.
          </p>
          <p>
            In addition to the Device Information, the mobile application that
            you use may automatically gather and preserve any or all of the
            following data from your device, including but not restricted to:
          </p>
          <ul>
            <li>Your choice of nation and speech website (if applicable)</li>
            <li>The developer of and model of mobile device you have</li>
            <li>The mobile platform you're accessing</li>
            <li>Your choice of mobile web browsers</li>
            <li>Your geographic location</li>
          </ul>
        </section>

        <section>
          <h2>SECURITY AND DATA PROTECTION</h2>
          <p>
            We take reasonable measures to protect your personal information from
            unauthorized access, disclosure, alteration, or destruction. Our
            security practices include encryption, firewalls, and secure access
            controls to safeguard your data. However, no system is completely
            secure, and we cannot guarantee the absolute security of your
            information.
          </p>
          <p>
            We encourage you to use a strong, unique password and keep it
            confidential. If you suspect any unauthorized access to your account,
            notify us immediately.
          </p>
        </section>

        <section>
          <h2>YOUR RIGHTS</h2>
          <p>
            As a user of our services, you have the right to access, update, or
            delete your personal information. You may also opt out of certain
            data collection practices. To exercise these rights, please contact
            us through the support section of our application or website.
          </p>
        </section>
        <section>
          <h2>Information about your interactions</h2>
          <p>
            Information about your interactions with the Application and any of our
            websites that the Application links, such as how often you use a
            particular aspect of the Application over the course of a given span of
            time, how often you use the Application, what actions you take within
            the App, and how you interact with it, Information that would enable us
            to customise the services and content made accessible through the app.
          </p>
          <p>
            Upon receiving device authorizations, we may use data from SMS/text
            messages for the following purposes: (i) granting and accepting one-time
            passwords and other device verification; (ii) filling out recognition
            details during monetary operations, whether they are conducted through
            us or a third-party service provider in accordance with the law. As
            stated in this Privacy Policy, we do not distribute or otherwise
            transfer SMS/text message data to any third parties.
          </p>
        </section>

        <section>
          <h2>How do we disclose to third parties and when?</h2>
          <h3>After you have consented to obtain information from third parties</h3>
          <p>
            There might be a chance for you to directly receive information and/or
            marketing offers from third parties. Your Protected Information will be
            shared with such third parties if you do agree to this, and any other
            information you submit will be subject to their privacy policies. You
            should evaluate the privacy policies and practices of such third parties
            before accepting to receive such information from them because we are
            not liable for their privacy policies and practices.
          </p>
          <h3>Third parties performing services in our authority</h3>
          <p>
            For example, drivers and the businesses they work to provide the
            services, while other third parties, host the services, design and/or
            operate the features, track the analytics of the services, process
            payments, take anti-fraud and security precautions, offer customer
            support, and provide geo location data to our drivers. Only the services
            they are providing for you or for us will require access to user
            information, including Protected Information. All of these outside
            parties, are expected to secure your information to the same extent as
            we do and are prohibited from sharing or using it for any other reason.
          </p>
          <h3>Contests and promotions</h3>
          <p>
            Through the Services, we might provide Promotions that call for
            registration. By taking part in a Promotion, you consent to the official
            rules that govern that Promotion, which may have specific demands of
            you. For example, you may have to let the Promotion's sponsor to use
            your name, voice, and/or likeness in promotional marketing. If you
            decide to participate in a promotion, you agree that your protected
            information may be made available to the public or to third parties in
            connection with the administration of that promotion, including the
            shortlisting of winners, the awarding of prizes, and as otherwise
            permitted by law or the official rules of the promotion, such as on a
            winners list.
          </p>
          <h3>Administrative and legal reasons</h3>
          <p>
            We work with governments, law enforcement officials and private
            organizations to enforce and comply with the law. Accordingly, we will
            access, use, retain, transfer, and disclose your information to
            government or law enforcement officials or private persons when we
            reasonably believe it is necessary and appropriate to:
          </p>
          <ul>
            <li>Comply with any applicable law, regulation, subpoena, or legal process</li>
            <li>
              Protect and/or defend (including investigating possible violations) of
              the Online and Mobile Services Terms of Use or other policies
              applicable to the Online and Mobile Services
            </li>
            <li>
              Protect the safety, rights, property or security of us, our services or
              others
            </li>
            <li>Protect public safety for any reason</li>
            <li>
              Detect, prevent or address fraud, security or technical issues
            </li>
            <li>
              Prevent or stop any activity that we determine to be illegal, unethical
              or lawful, or that may pose a risk.
            </li>
          </ul>
        </section>

        <section>
          <h2>When you share information</h2>
          <p>
            Secure data may be collected and disclosed to third parties. This is
            when you have content that you explicitly and knowingly upload, share or
            transmit from your mobile application to email recipients, online
            communities, websites or generally. Uploaded photos, posted reviews or
            comments, or information about you or your rides that you choose to
            share with others through features provided on our Service.
          </p>
          <p>
            Any such content uploaded, shared or submitted will also be subject to
            the privacy policy of the email, online community website, social media
            or other platform where the content is uploaded, shared or submitted.
          </p>
        </section>

        <section>
          <h2>Company transfer</h2>
          <p>
            For internal reasons, such as business and operational needs, we may
            share your information, including your Secured Information and Usage
            Information with our parent, subsidiaries, and affiliates. Additionally,
            we reserve the right to reveal and export all such information in the
            following circumstances:
          </p>
          <ul>
            <li>
              To a new owner, co-owner, or operator of the Services or relevant
              database
            </li>
            <li>
              In connection with a buyout, unification, rebuilding, the sale of
              virtually all of our subscription interests and/or assets, or other
              corporate change, even during any due diligence process
            </li>
          </ul>
        </section>

        <section>
          <h2>Market research and advantages</h2>
          <p>
            Your details, including your Protected Data and Usage Information, may
            be shared with third parties for any reason, including but not limited
            to carrying out research, data analysis, determining and customising
            product or service offerings, improving the products or Services, or
            providing you with any other advantages/services.
          </p>
        </section>

        <section>
          <h2>Links to third-party content and services</h2>
          <p>
            The Services might include material from third parties, and when web
            pages from any online or mobile Services are served to your browser,
            those third parties may gather information on how people use their
            websites as well as your Device Identifier. Furthermore, you can be sent
            to other websites or applications when using the Services that are run
            and managed by independent third parties that we do not control.
          </p>
          <p>
            The privacy policies followed by any of these third parties are not our
            responsibility. For instance, if you click on a banner advertisement,
            you can leave one of our websites and go to another website. These other
            websites may send you their own cookies, gather data on their own, or
            seek Protected Information from you. They may or may not have written
            privacy policies of their own. Before providing any third parties with
            Protected Information, we advise you to keep track of when you leave our
            Services and read the privacy policies of all third-party websites and
            applications.
          </p>
        </section>

        <section>
          <h2>Widgets and features of social media</h2>
          <p>
            Social networking tools like the Facebook Like button, widgets like the
            "Share this" button, and interactive mini-programs that run on our
            online and mobile services are all possible inclusions in our online and
            mobile services. In order for these services to work effectively, we may
            gather your IP address, photo, and the page you are currently viewing on
            our website or mobile application.
          </p>
          <p>
            Either a third party hosts the social networking features and widgets or
            our web services host them directly. The privacy policies of the
            businesses that provide these tools and widgets will control how you
            interact with them.
          </p>
        </section>

        <section>
          <h2>Data collected by drivers</h2>
          <p>
            The use of any information about you that the driver or the business to
            which the driver belongs learns while giving you a trip in a cab booked
            using the Services, or in any other way that is not given by us, shall
            not be covered by this Privacy Policy.
          </p>
        </section>

        <section>
          <h2>Change of Information and Account Cancellation</h2>
          <p>
            You are responsible for keeping the data you provide accurate,
            including any contact information when registering for an account.
            You can update your information on our member information page, send
            us a message via email, or make adjustments to your protected
            information. If you decide you no longer want our services, you can
            follow the instructions in an email or login to your account to cancel
            or amend messages you want to receive from our Services.
          </p>
          <p>
            We may terminate your access to the Services by sending you a written
            notice via email if the information you provided is insufficient or
            inaccurate. For account cancellation, please contact us via the email
            address listed on your trip bill.
          </p>
        </section>

        <section>
          <h2>Security</h2>
          <p>
            We use commercially acceptable security procedures to protect your
            information, including encryption, firewalls, and Secure Socket Layers.
            However, no security mechanism is unbreakable, and we cannot guarantee
            the security of our databases. Any information you communicate to us
            is at your own risk. We recommend keeping your password secret.
          </p>
        </section>

        <section>
          <h2>Developments in the Privacy Policy</h2>
          <p>
            We reserve the right to revise this Privacy Policy as our information
            practices change. Any modifications will take effect as soon as they
            are posted. If significant changes occur, we will notify you via email
            or by posting a notice. We encourage you to check this page regularly
            for updates.
          </p>
        </section>

        <section>
          <h2>Call and SMS Policy</h2>
          <ul>
            <li>
              We use calls and SMS to verify a user's phone number during account
              creation.
            </li>
            <li>
              Calls and SMS are used to communicate with riders regarding pickup
              locations, trip details, fare, and other relevant information.
            </li>
            <li>
              We prohibit the use of calls and SMS for marketing or data
              collection purposes.
            </li>
            <li>
              We implement security measures to protect user data with encryption
              and authentication protocols.
            </li>
            <li>
              Calls and SMS messages are only initiated by the user for service
              purposes.
            </li>
            <li>
              We use call and SMS data solely for providing and improving our
              transportation services and ensuring safety and security.
            </li>
          </ul>
        </section>

      </div>
      <Footer></Footer>
    </div>
  );
};

export default PrivacyPolicy;
